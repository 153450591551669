import React from 'react';
import Seo from '../../components/seo';
import PageTitle from '../../components/pagetitle';
import PageContent from '../../components/pagecontent';
import { Link } from 'gatsby';

const Thanks = () => (
  <main className='contact'>
    <Seo title='Oops we did it again' />
    <PageTitle
      pageTitle='Oops'
      pageTagline='Something appears to have gone '
      pageHighlight='wrong'
      pageClone='Failure'
    />
    <section className='form-info'>
      <PageContent
        heading='Please go back and try again'
        contentText='Or if you would like you can try again later or contact us directly using the information to the right'
      />
      <div className='btn__wrapper'>
        <Link
          title='Back to Homepage'
          className='btn cta__btn transform-scale-h'
          to='/'
        >
          Back to Homepage
        </Link>
      </div>
    </section>
  </main>
);

export default Thanks;
